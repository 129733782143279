import { ProductAttributeMap } from "~/types/ecom/product/attribute-map";
import type {
  Product,
  ProductAttribute,
} from "~/types/ecom/product/product.type";

export const useProductAttributeStore = defineStore("productAttributeStore", {
  state: () => ({}),
  actions: {
    // --------------------- Get product ratings from a scale of 1 to 5 ------------------------ //
    getProductRating(attributes: ProductAttribute[]): string | null {
      if(!attributes){
        return null;
      }
      const attribute = attributes.find(
        (attr) => attr.id === ProductAttributeMap.ReviewRating
      );
      return attribute ? attribute.value : null;
    },

 //-------------------- Get Product Additional Images------------------------------------//   
    getProductImages(attributes: ProductAttribute[]){
      if(!attributes){
        return null;
      }
      const attribute = attributes.find(
        (attr) => attr.id === ProductAttributeMap.AdditionalPhotos
      )
        return attribute ;
    },

    // --------------------- Get customer review numbers for the product ------------------------ //
    getNumberOfReviews(attributes: ProductAttribute[]): string | null {
      if(!attributes){
        return null;
      }
      const attribute = attributes.find(
        (attr) => attr.id === ProductAttributeMap.NumberOfReviews
      );

      return attribute ? attribute.value : null;
    },
    // --------------------- Get minimum allowable quantity information ------------------------ //
    getQuantityMinimum(attributes: ProductAttribute[]): string | null {
      if(!attributes){
        return null;
      }
      const attribute = attributes.find(
        (attr) => attr.id === ProductAttributeMap.QuantityMinimum
      );

      return attribute ? attribute.value : null;
    },
    // --------------------- Get delivery timeline for dropship delivery ------------------------ //
    getDropshipDeliveryTime(attributes: ProductAttribute[]): string | null {
      if(!attributes){
        return null;
      }
      const attribute = attributes.find(
        (attr) => attr.id === ProductAttributeMap.DropshipDeliveryTime
      );

      return attribute ? attribute.value : null;
    },
    // --------------------- Get offer value to be displayed on top right of the card ------------------------ //
    getTopRightTagValues(attributes: ProductAttribute[]): string[] | null {
      // @multiple web image top right overlay for same product are found
      if(!attributes){
        return null;
      }
      const topRightTagValues: string[] = attributes
        .filter((attr) => attr.id === ProductAttributeMap.TagOnTopRight)
        .map((attr) => attr.value);

      return topRightTagValues.length ? topRightTagValues : null;
    },
    // --------------------- Get combo deals and promo messages for specific product ------------------------ //
    getPromotionalMessages(attributes: ProductAttribute[]): string[] | null {
      // @multiple web image top right overlay for same product are found
      if(!attributes){
        return null;
      }
      const promoMessages: string[] = attributes
        .filter((attr) => attr.id === ProductAttributeMap.PromotionMessage)
        .map((attr) => attr.value);

      return promoMessages.length ? promoMessages : null;
    },
    // --------------------- Get offer value to be displayed on top right of the card ------------------------ //
    getDetailedDescriptionHTML(attributes: ProductAttribute[]): string {
      // @multiple detailed descriptions for same product are found
      if(!attributes){
        return "";
      }
      const attribute = attributes
        .filter((attr) => attr.id === ProductAttributeMap.DetailedDescription)
        .map((attr) => attr.value);

      return attribute.length ? attribute.join("").replace(/\n/g, "<br>") : "";
    },
    // --------------------- Get compare option displayed on bottom of the card ------------------------ //
    getCompareOption(attributes: ProductAttribute[]): string | null {
      if(!attributes){
        return "";
      }
      const attribute = attributes
        .filter((attr) => attr.id === ProductAttributeMap.CompareOption)
        .map((attr) => attr.value);
      return attribute.length ? attribute.join("").replace(/\n/g, "<br>") : "";
    },
  },
});
